import React from "react";

import {MainLayout} from "../components/layout"

import shared from "../styles/shared.module.styl";
import aboutStyles from "../styles/about.module.styl";
import { faPalette, faPenFancy, faGlasses, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bookImg from "../images/portadas/600x897.png";
import { graphql, StaticQuery } from "gatsby";


import raul from "../images/equipo/raul_aguayo.png";
import nico from "../images/equipo/nico.jpg";
import katia from "../images/equipo/katia.jpg";
import lucia from "../images/equipo/lucia.jpeg";
import mariana from "../images/equipo/mariana_ortiz.png";
import jorge from "../images/equipo/jorge_fernandez.png";
import sharon from "../images/equipo/sharon_picazo.png";

import { Helmety } from "../components/Head";

export const EquipoQuery = graphql`
query EquipoQuery {
  allEquipoJson {
    nodes {
      name
      position
      email
      area
    }
  }
}

`;

const ICON_MAP = {
  "arte": <FontAwesomeIcon fixedWidth icon={faPalette} />,
  "editorial": <FontAwesomeIcon fixedWidth icon={faPenFancy} />,
  "rp": <FontAwesomeIcon fixedWidth icon={faUsers} />,
  "lector": <FontAwesomeIcon fixedWidth icon={faGlasses} />
};

const TEAM_MAP = {
	"lucia@dharmabooks.com.mx": lucia,
	"katia@dharmabooks.com.mx": katia,
	"mariana@dharmabooks.com.mx": mariana,
  "jorge@dharmabooks.com.mx": jorge,
  "prensa@dharmabooks.com.mx": sharon,
};

export default class AcercaDe extends React.Component {
  render () {

    return (
      <MainLayout>
        <Helmety
          url="https://www.dharmabooks.com.mx/acerca/"
          title="Acerca de Dharma Books"
          content="Nos hemos puesto a pensar qué decir de esta casa editorial, pero no es fácil, entonces decidimos que lo mejor es contarles lo que somos desde nuestro corazón."
          imgURL="https://www.dharmabooks.com.mx/static_resources/otros/stand_fil_19.jpg"
        />
        <div className={shared.container}>
          <div className={shared.content}>
            <h1 className={aboutStyles.title}>Acerca de Dharma Books + Publishing</h1>
            <div className={aboutStyles.text}>
              <p>Nos hemos puesto a pensar qué decir de esta casa editorial, pero no es fácil, entonces decidimos
                que lo mejor es contarles lo que somos desde nuestro corazón:</p>
              <p>Dharma Books es una editorial que quiere crecer a la par de lo que la literatura y la poesía nos lo
                permitan. Es decir: creemos que vamos a editar y publicar sólo lo que estemos seguros que
                aportará algo importante y sustancioso a la conversación en México y en los países a donde
                lleguen nuestros libros. Dharma, entonces, armará y conformará su catálogo acorde a lo que los
                tiempos nos exijan, y también según los temas y las coyunturas.</p>
              <p>No podríamos decirte cuál es nuestra línea editorial, pero sí hay algo de lo
                que estamos seguros: buscamos historias que parten desde una necesidad interior, desde un
                malestar; lo que no queremos son libros plagados de fórmulas, de trampas, de caminos ya
                caminados. Buscamos lo nuevo, lo honesto y arriesgado. Queremos historias, poemas,
                pensamientos que nos ilustren un nuevo camino, donde tengamos una retroalimentación con
                quien haya decidido escribir y compartirnos un pedazo de sí. </p>
              <p>En Dharma hemos decidido conformar nuestro catálogo a través de cuatro vías que creemos que
                son las que hacen una labor editorial relevante:</p>
              <ul>
                <li>Labor de scouting en México y países de habla hispana.</li>
                <li>Convocatorias dirigidas hacia mujeres: narrativa, poesía y ensayo</li>
                <li>Leyendo detenidamente aquellos manuscritos no solicitados</li>
                <li>Proponiendo textos que aun no existen a autores y autoras que nos interesan</li>
              </ul>
              <p>Estas cuatro tareas editoriales son las que permanecerán a lo largo de la vida de esta editorial, y donde
                depositamos nuestra confianza en poder generar una oferta importante y valiosa para nuestras y
                nuestros lectores.</p>
              <p>Muchas gracias por tomarte el tiempo de leer esto, y ¡bienvenido/a a nuestro pequeño mundo de
                grandes libros!</p>
            </div>
            <h2 className={aboutStyles.subtitle}>Conoce al equipo</h2>
            <div className={aboutStyles.mainMemberList}>
              <div className={aboutStyles.member}>
                <img src={raul} alt="Raúl Aguayo" />
                <div className={aboutStyles.data}>
                  <p className={aboutStyles.name}>Raúl Aguayo</p>
                  <span className={aboutStyles.icon}>{ICON_MAP["arte"]}</span>
                  <p className={aboutStyles.position}>Director de Arte</p>
                  <p className={aboutStyles.mail}><a href="mailto:raul@dharmabooks.com.mx">raul@dharmabooks.com.mx</a></p>
                </div>
              </div>
              <div className={aboutStyles.member}>
                <img src={nico} alt="Nicolás Cuéllar" />
                <div className={aboutStyles.data}>
                  <p className={aboutStyles.name}>Nicolás Cuéllar</p>
                  <span className={aboutStyles.icon}>{ICON_MAP["editorial"]}</span>
                  <p className={aboutStyles.position}>Director Editorial</p>
                  <p className={aboutStyles.mail}><a href="mailto:nicolas@dharmabooks.com.mx">nicolas@dharmabooks.com.mx</a></p>
                </div>
              </div>
            </div>
            <div className={aboutStyles.memberList}>
              <StaticQuery
                query={EquipoQuery}
                render={data => {
                  const {allEquipoJson} = data;
                  const authorList = allEquipoJson.nodes.map(member => {
                    const img = TEAM_MAP[member.email] ? TEAM_MAP[member.email] : bookImg;
                    return (<div className={aboutStyles.teamMember}>
											<img src={img} alt={member.name} />
											<div className={aboutStyles.data}>
												<p className={aboutStyles.name}>{member.name}</p>
												<span className={aboutStyles.icon}>{ICON_MAP[member.area]}</span>
												<p className={aboutStyles.position}>{member.position}</p>
												<p className={aboutStyles.mail}><a href={`mailto:${member.email}`}>{member.email}</a></p>
											</div>
										</div>);
                  });
                  return authorList;
                }}
              />


            </div>
          </div>
        </div>
      </MainLayout>
    )
  }
}

